import axios from 'axios'

import { useAuthStore } from '@/stores/auth.store';
import { storeToRefs } from 'pinia';

const instance = axios.create({
  baseURL: process.env.VUE_APP_URL_API,
})

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

instance.interceptors.request.use(
  (config) => {
    const authStore = useAuthStore();
    const { token } = storeToRefs(authStore);
    const access_token = token.value?.access || "";
    if (access_token) {
      config.headers['Authorization'] = `Bearer ${access_token}`;
    }
    // Any status code within the range of 2xx cause this function to trigger
    // Do something with response data
    return config;
  }, 
  (error) => {
    console.log("[ error ] => ", error);
    // Any status codes outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response
  },
  (err) => {
    console.log("[ error ] => ", err);

    const originalRequest = err.config;
    const authStore = useAuthStore();
    const { refreshToken } = authStore

    // if (err.response.status === 401 && !originalRequest._retry) {
    if( err.response.status === 403 && err.response.data.code === "token_not_valid"){
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
            failedQueue.push({ resolve, reject });
          })
          .then(token => {
            originalRequest.headers['Authorization'] = 'Bearer ' + token;
            return axios(originalRequest);
          })
          .catch(err => {
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      // token expire
      return new Promise((resolve, reject) => {
          refreshToken()
            .then(tokenRes => {
              axios.defaults.headers.common['Authorization'] = `Bearer ${tokenRes.access}`;
              originalRequest.headers['Authorization'] = `Bearer ${tokenRes.access}`;
              processQueue(null, tokenRes.access);
              resolve(axios(originalRequest));
            })
            .catch(err => {
              console.log("[ fail token ] => ", err);
              processQueue(err, null);
              // store.dispatch(showMessage({ message: 'Expired Token' }));

              reject(err);
            })
            .then(() => {
                isRefreshing = false;
            });
      });
    }

    return Promise.reject(err);


    // // old
    // const authStore = useAuthStore();
    // const { refreshToken } = authStore

    // console.log(error.response)


    // if( error.response.status === 403 && error.response.data.code === "token_not_valid"){
    //   // token expire
    //   const prevRequestConfig = error.config;
    //   prevRequestConfig.sent = true;
    //   const tokenRes = await refreshToken();

    //   prevRequestConfig.headers = { ...prevRequestConfig.headers };

    //   prevRequestConfig.headers["Authorization"] = `Bearer ${tokenRes.access}`;
    //   return instance(prevRequestConfig);
    // }
    // return Promise.reject(error);
  }

)

export default instance;
