export { }
declare global {
  interface Number {
    format(n: number, x: number): string;
  }
}

Number.prototype.format = function(n, x){
  const re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\.' : '$') + ')';
  return Number(this).toFixed(Math.max(0, ~~n)).replace(new RegExp(re, 'g'), '$&,');
};