import { createApp } from 'vue'
import { createPinia } from 'pinia';
import App from './App.vue'
import router from './router'
import '@vuepic/vue-datepicker/dist/main.css'

import 'vue-datepicker-next/index.css';

import { library, dom } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'

import Vue3Toasity from 'vue3-toastify';
import 'vue3-toastify/dist/index.css'

import "@/helpers/number";

library.add(fas, far, fab)
dom.watch()

createApp(App)
  .component('font-awesome-icon', FontAwesomeIcon)
  .use(Vue3Toasity, {
    autoClose: 3000,
    position: "bottom-center",
    hideProgressBar: true,
    pauseOnHover: true,
    closeOnClick: true
  })
  .use(router)
  .use(createPinia())
  .mount('#app')
